<template>
  <div class="userAccountWrap mb20 bgWhite">
    <div class="tagWrap">
      <span class="tag" v-for="(item,index) in tagList" :class="{active:tagActive==index}" @click="changeTag(index)">{{item}}</span>
    </div>
    <div class="userAccount">
      <div v-if="tagActive == 0" class="walletWrap">
        <div class="balanceWrap">
          <div class="balance" :style="{backgroundImage: 'url('+walletBg1+')'}">
            <span class="tit">白金币余额：</span>
            <span class="num" v-if="userInfo.wallteInfo">{{userInfo.wallteInfo.chargeMoney}}</span>
            <span class="recharge" @click="goRecharge"></span>
          </div>
        </div>
        <div class="bindBalanceWrap">
          <div class="bindBalance" :style="{backgroundImage: 'url('+walletBg2+')'}">
            <span class="tit">绑定白金币余额：</span>
            <span class="num" v-if="userInfo.wallteInfo">{{userInfo.wallteInfo.giftMoney}}</span>
          </div>
        </div>
        <div class="divideWrap">
          <div class="divide">
            <el-form ref="form" :model="divideForm" label-width="100px">
              <el-form-item label="短信验证:">
                <div class="codeWrap">
                  <el-input v-model="divideForm.code" placeholder="验证码"></el-input>
                  <span v-if="!isSendCode" class="sendCode" @click="getSendCode">{{divideFormCodeTxt}}</span>
                  <span v-else class="sendCode">{{divideFormCount}}s</span>
                </div>
                <div class="tip codeTip">绑定手机将会收到验证短信</div>
              </el-form-item>

              <el-form-item label="划分金额:">
                <el-input v-model="divideForm.money" placeholder="请输入白金币数量"></el-input>
                <div class="tip moneyTip">划分比例：1白金币=100MB</div>
              </el-form-item>
              <el-form-item class="submitWrap">
                <el-button class="btn" type="primary" @click="divideFormSubmit">划分</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div v-else-if="tagActive == 1" class="vipWrap">
        <div class="userInfo">
          <div class="item item1">
            <img :src="vipIcon1" alt="">
            <div class="txt">
              <span class="tit">我的积分:</span>
              <span class="num">{{vipInfo.points}}</span>
              <span class="total">（累计：{{vipInfo.sumPoints}}）</span>
            </div>
          </div>
          <div class="item item1">
            <img :src="vipIcon2" alt="">
            <div class="txt">
              <span class="tit">VIP等级:</span>
              <span class="num">{{vipInfo.viplevel}}</span>
            </div>
          </div>
        </div>
        <div class="btnWrap">
          <img style="cursor: pointer" :src="vipIcon3" alt="" @click="vipMask">
          <img style="cursor: pointer" :src="vipIcon4" @click="goShop" alt="">
          <img style="cursor: pointer" :src="vipIcon5" @click="goRecharge" alt="">
        </div>
        <div class="integralWrap">
          <div class="item" v-for="(item,index) in vipInfo.vipconfigList">
            <img class="grade" :src="item.imgUrl" alt="">
            <span class="fen">{{item.title}}</span>
            <img v-if="item.isClick && item.btnText=='领取'" class="btn" :src="vipIcon6" alt="" @click="receive(item)">
            <img v-else-if="item.isClick==false && item.btnText=='领取'" class="btn" :src="vipIcon8">
            <img v-else class="btn" :src="vipIcon7" alt="">
          </div>
        </div>
        <div class="ruleWrap">
          <div class="tit">VIP规则说明</div>
          <div class="cons">
            <p class="txt1"><span class="num">1.</span><span>用户充值后可获得VIP积分，依据累计获取的积分提升VIP等级，目前最高等级为VIP10。（1RMB附赠1积分）。</span></p>
            <p class="txt1"><span class="num">2.</span><span>用户达到相应VIP等级后可领取对应等级VIP福利.</span></p>
            <p class="txt1"><span class="num">3.</span><span>VIP福利可累计获取但每种仅限一次。</span></p>
            <p class="txt1"><span class="num">4.</span><span>幸运钥匙以及精品服饰兑换券请至网站商城相应货币栏查看。VIP专属徽章请登录游戏后点击个人信息徽章页查收。（领取成功后30分钟内到账）。</span></p>
            <p class="txt1"><span class="num">5.</span><span>VIP用户发送公告时可展示最高VIP等级。</span></p>
            <p class="txt1"><span class="num">6.</span><span>用户达到VIP10以后依然可以正常获取积分。</span></p>
          </div>
        </div>
      </div>
      <div v-else-if="tagActive == 2" class="bubbleWrap">
        <div class="userInfo">
          <div class="item">
            <span class="tit">我的绑定白金币:</span>
            <span class="txt">
              <template v-if="userInfo.wallteInfo">{{Number(userInfo.wallteInfo.giftMoney)}}</template>  <!--{{Number(userInfo.wallteInfo.chargeMoney) + Number(userInfo.wallteInfo.giftMoney)}}-->
              <template v-else>0</template>
            </span>
          </div>
          <div class="item"><span class="tit">我的等级:</span><span class="txt">{{userInfo.gameDataInfo.roleLevel}}</span><img @click="getUserBubble(4)" :src="require('@/assets/imgs/account/refresh.png')" alt=""></div>
          <div class="item"><span class="tit">我的情侣红心:</span><span class="txt">{{userInfo.gameDataInfo.coupleExp}}</span><img @click="getUserBubble(2)" :src="require('@/assets/imgs/account/refresh.png')" alt=""></div>
          <div class="item"><span class="tit">我的泡点:</span><span class="txt">{{userInfo.gameDataInfo.onlineTime}}</span><span class="exchange" @click="getUserBubble(1)" >保存泡点</span></div>
          <div class="item"><span class="tit">我的蜜糖之心:</span><span class="txt">{{userInfo.gameDataInfo.sisterExp}}</span><img @click="getUserBubble(3)" :src="require('@/assets/imgs/account/refresh.png')" alt=""></div>
          <div class="item"><span class="tit">上次保存时间:</span><span class="txt">{{userInfo.gameDataInfo.lastUpdateDate}}</span></div>
        </div>
        <div class="bubbleInfo mb20">
          <div class="des">
            <div class="tit">泡点说明</div>
            <p>1.泡点依据您的实际在线游戏时长进行统计，每满一分钟可获取10泡点。</p>
            <p>2.泡点可以兑换绑定白金币、经验、情侣红心以及蜜糖之心。</p>
            <p>3.兑换经验、情侣红心以及蜜糖之心成功后可刷新查看（刷新CD为1分钟）。</p>
            <p>4.绑定白金币每天兑换上限为100绑定白金币。</p>
            <p>5.兑换G币成功后请重新登录游戏查看（15分钟内到账）。</p>
            <p>6.保存泡点前请先正常退出游戏。</p>
            <p>7.非正常退出游戏可能导致您本次登录期间所获得泡点被清零。</p>
            <p>8.部分不可抗力因素（包括但不限于运营商网络故障、网络攻击、自然灾害等。）可能导致用户泡点无法正常获取。</p>
          </div>
        </div>
        <div class="bubbleInfo mb20">
          <div class="cons">
            <div class="item" v-for="(item,index) in bubbleList"><el-radio v-model="bubbleValue" :label="item.exchanegId">{{item.title}}</el-radio></div>
          </div>
        </div>
        <div class="confirmWrap">
          <span @click="confirmExchange">确认兑换</span>
        </div>
      </div>
    </div>
    <pubMask v-if="isShowPask" :isShowPask="isShowPask" :propShowSwitch="showSwitch" :title="maskTitle" :tipCon="tipCon" @determine="determineFn"></pubMask>
  </div>
</template>

<script>
  import axios from "axios";
  import Cookies from "js-cookie";
  import eventBus from '@/common/bus.js'
  import pubMask from '@/components/pubMask'
  import {publicUrl} from '@/common/publicUrl.js'
  // 自定义弹窗
  // import Messages  from "@/common/Message/index.js";

  export default {
    name: "index",
    components:{
      pubMask
    },
    data(){
      return{
        walletBg1:require('@/assets/imgs/account/walletBg1.png'),
        walletBg2:require('@/assets/imgs/account/walletBg2.png'),
        vipIcon1:require('@/assets/imgs/account/vipIcon1.png'),
        vipIcon2:require('@/assets/imgs/account/vipIcon2.png'),
        vipIcon3:require('@/assets/imgs/account/vipIcon3.png'),
        vipIcon4:require('@/assets/imgs/account/vipIcon4.png'),
        vipIcon5:require('@/assets/imgs/account/vipIcon5.png'),
        vipIcon6:require('@/assets/imgs/account/vipIcon6.png'),
        vipIcon7:require('@/assets/imgs/account/vipIcon7.png'),
        vipIcon8:require('@/assets/imgs/account/vipIcon8.png'),
        tagActive:this.$route.query.type?this.$route.query.type:0,
        tagList:['我的钱包','VIP会员','泡点'],
        userInfo:{
          verifyInfo:{},
          vipInfo:{},
          wallteInfo:{
            chargeMoney:'',
            giftMoney:'',
          },
          gameDataInfo:{
            onlineTime:0,
            coupleExp:0,
            sisterExp:0,
            roleLevel:1,
            lastUpdateDate:'', //上次保存时间
          }
        },
        isShowPask:false,
        showSwitch:4,
        maskTitle:'VIP福利',

        codeImg:'',
        isSendCode:false,
        divideFormCodeTxt:'免费获取',
        divideFormCount:60,
        divideFormTimer:null,
        divideForm:{        // 划分
          code:'',
          money:'',
        },
        vipInfo:{
          points:'',
          sumPoints:'',
          viplevel:'',
          vipconfigList:[],
        },
        bubbleValue:'',
        bubbleList:[],
        tipCon:'',
      }
    },
    created() {
      document.documentElement.scrollTop = 0
      eventBus.$on('closeMask',this.closeMask)
    },
    mounted(){
      let _this = this
      this.init()
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
      
      window.addEventListener('setItem', ()=> {
        _this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
      });
      if(this.tagActive == 0){

      }else if(this.tagActive == 1){
        this.getVipList()
      }else if(this.tagActive == 2){
        this.getBubbleList()        // 获取泡点列表
      }
    },
    methods:{
      /* 泡点相关 */
      getUserBubble(type){
        axios({
          method: 'post',
          url: '/account/getUserGameData',
          headers: {
            token: Cookies.get('wx_token'),
          },
          data: {
            userId: Cookies.get('wx_userId'),      // userId
            type:type,
            platForm:'web',
          },
        }).then((res) => {
          if(res.data.state == 200){
            if(type == 1){
              this.userInfo.gameDataInfo.onlineTime = res.data.data.totalNum
              this.userInfo.gameDataInfo.lastUpdateDate = res.data.data.lastUpdateDate
            }else if(type == 2){
              this.userInfo.gameDataInfo.coupleExp = res.data.data.totalNum
            }else if(type == 3){
              this.userInfo.gameDataInfo.sisterExp = res.data.data.totalNum
            }else if(type == 4){
              this.userInfo.gameDataInfo.roleLevel = res.data.data.level
            }
            this.resetSetItem('userInfo', JSON.stringify(this.userInfo))
          }else if(res.data.state == 500){
            this.$message.error(res.data.message)
          }else{
            this.$message.error(res.data.message)
          }
        }).catch(err=>{
          this.$message.error(err.message)
        })
      },      // 获取用户泡点信息
      getBubbleList(){
        axios({
          method: 'post',
          url: '/account/getExchangeList',
          headers: {
            token: Cookies.get('wx_token'),
          },
          data: {
            userId: Cookies.get('wx_userId'),      // userId
            platForm:'web',
          },
        }).then((res) => {
          if(res.data.state == 200){
            // console.log(res.data.data.exchangeOneList);
            this.bubbleList = res.data.data.exchangeOneList
            this.bubbleValue = this.bubbleList[0].exchanegId
          }else{
            this.$message.error(res.data.message)
          }
        }).catch(err=>{
          this.$message.error(err.message)
        })
      },            // 获取泡点列表信息

      determineFn(){
        this.isShowPask = false;
        this.showSwitch = ''
        this.tipCon = ''
        axios({
          method: 'post',
          url: '/account/doExchange',
          headers: {
            token: Cookies.get('wx_token'),
          },
          data: {
            userId: Cookies.get('wx_userId'),      // userId
            exchangeId:this.bubbleValue,
            platForm:'web',
          },
        }).then((res) => {
          if(res.data.state == 200){
            this.$message.success(res.data.message)
            this.getMemberInfo()

          }else{
            this.$message.error(res.data.message)
          }
        }).catch(err=>{
          this.$message.error(err.message)
        })
      },
      confirmExchange(){
        this.isShowPask = true;
        this.showSwitch = 5
        this.tipCon = '兑换后不可退换，是否确认兑换'
      },      // 确认兑换
      receive(item){
        axios({
          method: 'post',
          url: '/account/getVipPrize',
          headers: {
            token: Cookies.get('wx_token'),
          },
          data: {
            levelId:item.levelId,
            userId: Cookies.get('wx_userId'),      // userId
            platForm:'web',
          },
        }).then((res) => {
          if(res.data.state == 200){
            this.$message.success(res.data.message)
            this.getVipList()
          }else{
            this.$message.error(res.data.message)
          }
        }).catch(err=>{
          this.$message.error(err.message)
        })
      },              // 领取VIP会员积分
      goShop(){
        window.open(publicUrl.shopIntegralUrl,'_blank')
      },
      goRecharge(){
        this.$router.push('/recharge')
      },                // 去充值页面
      goRecord(){
        this.$router.push('/record?type=4')
      },               // 进入充值记录

      getSendCode(){
        let _this = this
        window.nvc.getNVCValAsync(function (nvcVal) {
          axios({
            method: 'post',
            url: '/sms/sendMsgTouser',
            headers: {
              token: Cookies.get('wx_token'),
            },
            data: {
              username:_this.userInfo.username,
              mobile:_this.userInfo.mobile,
              type:'6',   // 新手机号
              userId: Cookies.get('wx_userId'),      // userId
              nvcVal:nvcVal,
            },
          }).then((res) => {
            if(res.data.state == 200){
              _this.$message.success(res.data.message)
              // Messages("success", res.data.message)
              _this.isSendCode = true
              _this.divideFormTimer = setInterval(()=>{
                _this.countDown(6)
              },1000)
              _this.business_handle(res);
            }else{
              _this.$message.error(res.data.message)
              // Messages("error", res.data.message)
            }
          }).catch(err=>{
            _this.$message.error(err.message)
            // Messages("error", err.message)
          })
        })
      },
      countDown(type){
        let _this = this
        if(type == 6){          // 忘记密码，找回密码
          if(this.divideFormCount == 0){
            this.divideFormCount = 60
            this.isSendCode = false
            this.divideFormCodeTxt = '重新获取'
            window.clearInterval(_this.divideFormTimer); // 清除定时器
            this.divideFormTimer = null;
          }else{
            this.divideFormCount--
          }
        }
      },          // 倒计时
      getMemberInfo(){
        axios({
          method: 'post',
          url: '/user/getMemberInfo',
          headers: {
            token: Cookies.get('wx_token'),
          },
          data: {
            userId: Cookies.get('wx_userId'),      // userId
            platForm:'web',
          },
        }).then((res) => {
          if(res.data.state == 200){
            this.userInfo=res.data.data
            this.resetSetItem('userInfo', JSON.stringify(res.data.data))
          }else{
            this.$message.error(res.data.message)
          }
        }).catch(err=>{
          this.$message.error(err.message)
        })
      },          // 获取用户信息
      divideFormSubmit(){
        var reg = /^[1-9][0-9]*$/;
        if(this.divideForm.code == ''){
          this.$message.error('请输入验证码')
        }else if(this.divideForm.money == ''){
          this.$message.error('请输入划分金额')
        }else if(this.divideForm.money == 0){
          this.$message.error('输入大于0的，不能输入负数')
        }else if(isNaN(this.divideForm.money)){
          this.$message.error('划分金额只能是纯数字')
        }else if(Number(this.divideForm.money) <= 0){
          this.$message.error('划分金额不能小于0')
        }else if(!(reg.test(this.divideForm.money))){
          this.$message.error('请输入大于等于1的整数')
        }else if(Number(this.divideForm.money) > 10000){
          this.$message.error('单笔划分金额最高为10000白金币')
        } else{
          axios({
            method: 'post',
            url: '/account/toDivide',
            headers: {
              token: Cookies.get('wx_token'),
            },
            data: {
              coins:this.divideForm.money,
              platForm:'web',
              userId: Cookies.get('wx_userId'),      // userId
              yzmcode:this.divideForm.code,
            },
          }).then((res) => {
            if(res.data.state == 200){
              let _this = this
              this.$message.success(res.data.message)
              this.divideForm.code = ''
              this.divideForm.money = ''
              this.divideFormCount = 60
              this.isSendCode = false
              this.divideFormCodeTxt = '免费获取'
              window.clearInterval(_this.divideFormTimer); // 清除定时器
              this.divideFormTimer = null;
              this.getMemberInfo()
            }else{
              this.$message.error(res.data.message)
            }
          }).catch(err=>{
            this.$message.error(err.message)
          })
        }
      },       // 划分
      changeTag(index){
        this.tagActive = index;
        if(index == 0){

        }else if(index == 1){
          this.getVipList()
        }else{
          this.getBubbleList()        // 获取泡点列表
        }
      },   // 切换我的钱包，vip，泡点
      getVipList(){
        axios({
          method: 'post',
          url: '/account/getVipInfo',
          headers: {
            token: Cookies.get('wx_token'),
          },
          data: {
            userId: Cookies.get('wx_userId'),      // userId
            platForm:'web',
          },
        }).then((res) => {
          if(res.data.state == 200){
            this.vipInfo = res.data.data
          }else{
            this.$message.error(res.data.message)
          }
        }).catch(err=>{
          this.$message.error(err.message)
        })
      },       // 获取VIPlist
      vipMask(){
        this.isShowPask = true;
        this.showSwitch = 4
        this.maskTitle = 'VIP福利'
      },          // VIP福利弹窗
      closeMask(){
        this.isShowPask = false
      },
      /* 阿里无痕验证 */
      init() {
        let _this = this
        var appKey = 'FFFF0N1N00000000A7D3'
        var scene = 'nvc_message'

        // 实例化nvc 对无痕验证进行初始化操作
        AWSC.use("nvc", function (state, module) {
          console.log(3333,module);
          // 初始化 调用module.init进行初始化
          window.nvc = module.init({
            // 应用类型标识。它和使用场景标识（scene字段）一起决定了无痕验证的业务场景与后端对应使用的策略模型。您可以在阿里云验证码控制台的配置管理页签找到对应的appkey字段值，请务必正确填写。
            appkey: appKey,
            //使用场景标识。它和应用类型标识（appkey字段）一起决定了无痕验证的业务场景与后端对应使用的策略模型。您可以在阿里云验证码控制台的配置管理页签找到对应的scene值，请务必正确填写。
            scene: scene,
            // 二次验证获取人机信息串，跟随业务请求一起上传至业务服务器，由业务服务器进行验签。
            success: function (data) {
              console.log(111);
              window.console && console.log(data)
              _this.verificationData(data, 'nc');
            },
            // 前端二次验证失败时触发该回调参数
            fail: function (failCode) {
              console.log(222);
              window.console && console.log("fail:" + failCode)
            },
            // 前端二次验证加载异常时触发该回调参数。
            error: function (errorCode) {
              console.log(666);
              window.console && console.log("error:" + errorCode)
            }
          });
          console.log(window.nvc);
        });
      },
      verificationData(data, type) {},
      business_handle(data) {
        console.log(JSON.stringify(data));
        // 业务服务器请求回调控制是否需要二次验证
        if (data.code == '100' || data.code == '200') {
          // 无痕验证通过
          console.log("验证通过!");
          // 去注册或登录
        } else if (data.code == '800' || data.code == '900') {
          // 无痕验证失败，直接拦截
          console.log("验证失败!");
          // 跳转到账号密码页
          var ncoption = {
            // 声明滑动验证需要渲染的目标ID。
            renderTo: "nc",
            // 二次验证文案配置
            upLang: {
              'cn': {
                //加载状态提示。
                'LOADING': "加载中...",
                //等待滑动状态提示。
                'SLIDE': "请向右滑动验证",
                //验证通过状态提示。
                'SUCCESS': "验证通过",
                //验证失败触发拦截状态提示。
                'ERROR': "非常抱歉，网络出错了...",
                //验证失败触发拦截状态提示。
                'FAIL': "验证失败，请重试"
              }
            }
          }
          // 唤醒二次验证（滑动验证码）
          window.nvc.getNC(ncoption);
        } else if (data.code == '400') {
          // 无痕验证失败，触发二次验证
          // 二次验证码（滑动验证码）配置项设置，详情请见滑动验证集成方式文档
          // 二次验证的appkey，scene，test值及success，fail，error的回调由nvc初始化时决定，请不要在二次验证时传入
          var ncoption = {
            // 声明滑动验证需要渲染的目标ID。
            renderTo: "nc",
            // 二次验证文案配置
            upLang: {
              'cn': {
                //加载状态提示。
                'LOADING': "加载中...",
                //等待滑动状态提示。
                'SLIDE': "请向右滑动验证",
                //验证通过状态提示。
                'SUCCESS': "验证通过",
                //验证失败触发拦截状态提示。
                'ERROR': "非常抱歉，网络出错了...",
                //验证失败触发拦截状态提示。
                'FAIL': "验证失败，请重试"
              }
            }
          }
          // 唤醒二次验证（滑动验证码）
          window.nvc.getNC(ncoption);
        }
      },    // 处理业务返回结果：人机信息串上传接口的回调函数，通过业务服务端的返回结果，控制无痕验证的不同状态。
    },
  }
</script>

<style scoped lang="less">
  .userAccountWrap{
    padding:30px 30px 20px;
    box-shadow: 0px 2px 8px 0px #EED9FF, 0px 0px 4px 0px rgba(255,255,255,0.25);
    .tagWrap{
      display: flex;
      margin-bottom: 10px;
      border-bottom: 1px solid #333333;
      .tag{
        margin-right: 100px;
        padding:8px 0;
        font-size: 20px;
        cursor: pointer;
        border-bottom: 4px solid #ffffff;
      }
      .active{
        font-size: 26px;
        border-bottom: 4px solid #D22190;
      }
    }
    .walletWrap{
      display: flex;
      justify-content: space-between;
      .balanceWrap,.bindBalanceWrap{
        padding-top:10px;
      }
      .balance,.bindBalance{
        padding:30px 15px;
        background-repeat: no-repeat;
        height: 128px;
        box-sizing: border-box;
        color: #ffffff;
        .tit{
          margin-bottom: 20px;
          font-size:16px;
        }
        .num{
          display: block;
          margin-top:20px;
          font-size: 30px;
        }
      }
      .balance{
        position: relative;
        z-index: 1;
        padding-right: 30px;
        width: 236px;
        min-width: 236px;
        .recharge{
          position: absolute;
          right: 1px;
          bottom: 6px;
          z-index: 2;
          display: inline-block;
          width: 30px;
          height: 90px;
          text-align: center;
          cursor: pointer;
        }
      }
      .bindBalance{
        width: 212px;
        min-width: 212px;
      }
      .divide{
        margin-right: 50px;
        width: 320px;
        min-width: 320px;
        .tip{
          color: #FF0000;
          height: 20px;
          line-height: 20px;
          font-size: 12px;
        }
        .codeWrap{
          display: flex;
          align-items: center;
          justify-content: space-between;
          .sendCode{
            display: inline-block;
            width: 77px;
            min-width: 77px;
            height: 36px;
            text-align: center;
            color: #ffffff;
            cursor: pointer;
            background:#333333;
          }
        }
      }
    }
    .vipWrap{
      padding-top:10px;
      .userInfo{
        margin-bottom: 25px;
        display: flex;
        justify-content: space-between;
        .item{
          display: flex;
          align-items: center;
          .txt{
            span{
              display: inline-block;
              font-size: 18px;
            }
          }
          .num{
            margin-left: 5px;
            padding:5px 0;
            width: 220px;
            min-width:220px;
            border-bottom: 1px dashed #333333;
          }
        }
      }
      .btnWrap{
        margin-bottom: 20px;
        img{
          margin-right:40px;
        }
      }
      .integralWrap{
        overflow: hidden;
        margin-right: -30px;
        .item{
          margin-right: 30px;
          margin-bottom: 30px;
          float: left;
          width: 150px;
          text-align: center;
          span{
            display: block;
            font-size: 20px;
          }
          .grade{
            height: 80px;
            line-height: 80px;
            font-weight: 700;
            background: rgba(210,33,144,0.1);
            border-radius: 4px 4px 4px 4px;
          }
          .fen{
            height: 38px;
            line-height: 38px;
          }
          .btn{
            cursor: pointer;
          }
        }
      }
      .ruleWrap{
        .tit{
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 10px;
        }
        .cons{
          padding:30px 20px 30px 30px;
          min-height: 200px;
          line-height: 24px;
          background: rgba(210,33,144,0.1);
          border-radius: 10px 10px 10px 10px;
          p{
            margin-bottom: 10px;
          }
          .txt1{
            margin-top:15px;
            display: flex;
            span{
              font-size:16px;
            }
            .num{
              min-width:20px;
            }
          }
        }
      }
    }
    .bubbleWrap{
      padding-top:10px;
      .userInfo{
        padding-bottom: 30px;
        overflow: hidden;
        .item{
          margin-bottom: 10px;
          float: left;
          width: 50%;
          height: 34px;
          span{
            display: inline-block;
          }
          img{
            transform: translateY(5px);
            cursor:pointer;
          }
          .tit{
            margin-right: 5px;
            width: 135px;
            text-align: right;
            font-size: 18px;
          }
          .txt{
            padding: 5px 0;
            width: 210px;
            font-size: 18px;
            border-bottom: 1px dashed #333333;
          }
          .exchange{
            margin-left: 5px;
            width: 90px;
            height: 30px;
            line-height: 30px;
            color: #ffffff;
            text-align: center;
            background: #D22190;
            border-radius: 4px;
            cursor: pointer;
          }
        }
      }
      .bubbleInfo{
        .des{
          padding:40px;
          background: rgba(210,33,144,0.1);
          border-radius: 10px 10px 10px 10px;
          .tit{
            margin-bottom: 20px;
            font-size: 20px;
            font-weight: 700;
            color: #fff;
            -webkit-text-stroke: 1px #bc49de; /* Safari */
            text-stroke: 1px #bc49de;
            letter-spacing: 3px;
            text-align: center;
          }
          p{
            margin-bottom: 15px;
            font-size: 16px;
            color: #bc49de;
          }
        }
        .cons{
          border-left:1px solid #D22190;
          border-top:1px solid #D22190;
          overflow: hidden;
          .item{
            float: left;
            padding-left:60px;
            width: 50%;
            height: 100px;
            line-height: 100px;
            border-right:1px solid #D22190;
            border-bottom:1px solid #D22190;
            box-sizing: border-box;
          }
        }
      }
      .confirmWrap{
        text-align: center;
        span{
          display: inline-block;
          width: 150px;
          height: 40px;
          line-height: 40px;
          font-size: 20px;
          color: #ffffff;
          background: #D22190;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
  }
</style>
